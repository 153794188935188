exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=default" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=default" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-swiper-test-tsx": () => import("./../../../src/pages/swiper-test.tsx?export=default" /* webpackChunkName: "component---src-pages-swiper-test-tsx" */)
}



exports.head = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx?export=head" /* webpackChunkName: "component---src-pages-404-tsxhead" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx?export=head" /* webpackChunkName: "component---src-pages-index-tsxhead" */),
  "component---src-pages-swiper-test-tsx": () => import("./../../../src/pages/swiper-test.tsx?export=head" /* webpackChunkName: "component---src-pages-swiper-test-tsxhead" */)
}

